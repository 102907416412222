.floating-label-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;

  &:focus {
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;
  }
  input {
    padding: 10px 0;
    border-bottom: thick solid green;
    width: 100%;
    font-size: 18px;
  }

  .floating-label {
    font-size: 18px;
    color: #cccccc;
    position: absolute;
    pointer-events: none;
    top: 9px;
    left: 12px;
    transition: all 0.1s ease;
    text-transform: uppercase;
  }

  textarea {
    padding: 10px 0;
    border-bottom: thick solid green;

    width: 100%;
    font-size: 18px;
    outline-color: transparent;
    outline-width: 0;
    outline-style: none;

    &:focus,
    &:not(:focus):not(:placeholder-shown) {
      outline-color: transparent;
      outline-width: 0;
      outline-style: none;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }

  input:focus:not(.readonly) ~ .floating-label,
  input:not(:focus):not(:placeholder-shown) ~ .floating-label,
  textarea:focus:not(.readonly) ~ .floating-label,
  textarea:not(:focus):not(:placeholder-shown) ~ .floating-label {
    top: -8px;
    bottom: 0px;
    left: 0px;
    font-size: 14px;
    opacity: 1;
    color: #404040;
    text-decoration: underline;
  }
  input:focus {
    outline-color: transparent;
    outline-style: none;
  }
  textarea:not(:placeholder-shown):invalid ~ .floating-label,
  input:not(:placeholder-shown):invalid ~ .floating-label,
  .error {
    color: red !important;
  }
}