input {
  border: none;
}

img {
  width: 100%;
  max-width: 100%;
}

.articleDetailsContainer {
  display: flex;
  justify-content: space-evenly;

  &>div {
    flex: 40% 0 0
  }

  .relationships {
    &>div {
      margin: 25px 0;

      label {
        display: block;
        padding: 5px 0;
        color: green;
        font-weight: bolder;
      }
    }
  }
}